.titlesWrapper .title {
    color: #322E48;
    font-size: 20px;
    margin-bottom: 8px;
    line-height: normal;
}

.titlesWrapper .title.bigger {
    font-family: Sora, sans-serif;
    color: #322E48;
    font-weight: 700;
    font-size: 24px;
    line-height: normal;
    text-align: center;
    letter-spacing: .36px;
}

.titlesWrapper .title span {
    color: #4C61C7;
}

.titlesWrapper .title.w800 {
    font-weight: 800;
}

.titlesWrapper .title.w700 {
    font-weight: 700;
}

.titlesWrapper .title.w600 {
    font-weight: 600;
}

.titlesWrapper .title.centered {
    text-align: center;
}

.titlesWrapper .title.left {
    text-align: left;
}

.titlesWrapper .subtitle {
    font-family: Sora, sans-serif;
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.408px;
}