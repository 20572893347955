.container {
    position: relative;
    margin-bottom: 12px;
}

.checkbox {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: 100;
    cursor: pointer;
}

.checked.option {
    background-color: #DDE4FC;
}

.option {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 16px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    min-height: 64px;
    background: #fff;
}

.icon svg {
    display: block;
    margin-right: 14px;
}

.titleContainer {
    width: 100%;
    height: 100%;
    /*padding-right: 12px;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: pre-wrap;
}

.titleContainer .active {
    font-weight: 600;
    color: #3749AB;
}

.subTitle {
    font-size: 12px;
    line-height: 16px;
}

