.container {
    position: relative;
}

.checkbox {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: 100;
    cursor: pointer;
}

.checked.option {
    border: 2px solid #4C61C7;
    background: #DDE4FC;
}

.option {
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-radius: 100px;
    border: 2px solid #fff;
    height: 48px;
    background: #fff;
    cursor: pointer;
    box-shadow: 0px 0px 16px 0px #00000014;
}

.titleContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: pre-wrap;
}

.titleContainer .active {
    color: #3749AB;
}