.container {
    margin: 0 auto;
    min-width: 340px;
    max-width: 420px;
}

@media screen and (min-width: 769px) {
    .container {
        max-width: calc(576px + 32px);
    }
}

@media screen and (min-width: 1440px) {
    .container {
        max-width: calc(576px + 32px);
    }
}