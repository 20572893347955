.container {
    position: relative;
    padding: 12px 16px 12px 16px;
    color: #322E48;
    font-size: 12px;
    line-height: 16px;
    border-radius: 8px;
    background-color: #FDE7DE;
    display: flex;
    align-items: center;
    gap: 16px;
}

.alertIcon {
    display: block;
    min-width: 24px;
}

.linkContainer a {
    text-decoration: underline;
}