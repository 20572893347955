.container {
    padding: 16px 16px 0;
}

.disabledSmoothScroll {
    position: fixed;
    z-index: 10;
    width: 100%;
}

.innerContainer {
    position: relative;
    z-index: 100;
    min-width: 328px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.backArrow {
    margin-left: 7px;
    padding: 8px 8px 8px 0;
    cursor: pointer;
    position: absolute;
}

.iconBack {
    cursor: pointer;
}

.logo {
    margin: 7px auto;
}

.pageCounter {
    font-size: 14px;
    font-weight: 700;
}

.pageStart {
    color: #4C61C7;
}

.pageEnd,
.pageSeparator {
    color: #768ADD;
}

.pageSeparator {
    margin: 0 3px;
}

.progressBox {
    position: relative;
    width: 100%;
    height: 4px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #E7E7F4;
}

.progressBar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 5px;
    background-color: #4C61C7;
    transition: width 0.2s linear;
}
