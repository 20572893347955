/* hard color */
.ReactModal__Content {
    position: absolute;
    background: #fff;
    overflow: auto;
    outline: none;
    padding: 16px;
    border: none;
    /*border-radius: 0;*/
    /*top: 0;*/
    right: 0;
    bottom: 0;
    left: 0;
    inset: 0;
    width: 100%;
    top: 64px;
    border-radius: 12px 12px 0 0 !important;
}

.ReactModal__Content__withTransparency {
    /*top: 64px;*/
    /*border-radius: 12px 12px 0 0 !important;*/
}

.ReactModal__Content__confirmationModal {
    top: 50%;
    padding: 24px;
    border-radius: 20px !important;
    box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.16);
}

.ReactModal__Overlay {
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    inset: 0;
    background-color: rgb(255 255 255 / 75%);
    transition: 0.3s;
}

.ReactModal__Overlay__isClose {
    opacity: 0;
    transform: translate(0, 20px);
    visibility: hidden;
}

.ReactModal__Overlay__isOpen {
    opacity: 1;
    transform: translate(0, 0);
    visibility: visible;
}

.ReactModal__Overlay__withTransparency {
    background: rgb(0 0 0 / 50%) !important;
}

.ReactModal__Content__withPopover {
    /*border-radius: 12px;*/
    right: 0;
    bottom: 0;
    left: 0;
    /*inset: 0;*/
    width: 100%;
    height: unset;
    max-width: unset;
    transform: unset;
    top: 64px;
    border-radius: 12px 12px 0 0 !important;
}

.ReactModal__Content__pageModal {
    top: 0;
    border-radius: 0 !important;
    width: 100%;
    height: 100%;
}

.ReactModal__Content__exclusiveOffer {
    padding: 0 !important;
    top: 0;
    border-radius: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ReactModal__Content__top182 {
    top: 160px;
}

.ReactModal__Content__padding26 {
    padding-left: 26px;
    padding-right: 26px;
}

.ReactModal__Content__paddingTop32 {
    padding-top: 32px;
}

.ReactModal__Content__paddingTop40 {
    padding-top: 40px;
}

.ReactModal__Content__noOverflow {
    overflow: hidden;
}

@media screen and (min-width: 1024px) {
    .ReactModal__Content__desktop {
        top: 50%;
        right: unset;
        bottom: unset;
        left: 50%;
        width: 376px;
        border-radius: 12px !important;
        transform: translate(-50%, -50%);
    }

    .ReactModal__Content__exclusiveOffer {
        width: 100%;
        height: 100%;
        border-radius: 0 !important;
    }

    .ReactModal__Content__pageModal {
        width: 100%;
        top: 0;
        left: 0;
        transform: unset;
        right: 0;
        bottom: 0;
        border-radius: 0 !important;
    }
}
