.container {
    position: relative;
    padding: 8px 9px 8px 32px;
    font-size: 12px;
    line-height: 16px;
    color: #424242;
    border-radius: 12px;
}

.container.success {
    background: rgba(85, 190, 139, 0.2);
}

.container.fail {
    background: #F2F2F7;
}

.container.disclaimer {
    color: #322E48;
    border-radius: 16px;
    background: #DDE4FC;
    padding: 12px 16px 12px 50px;
}

.container.sex-block {
    color: #2D7E5D;
    border-radius: 16px;
    border: 1px solid #D5F1B6;
    background: #E9FAD1;
    padding: 12px 12px 12px 50px;
}

.container.sex-block .icon {
    font-size: 26px;
    font-weight: 400;
    line-height: 24px;
    left: 16px;
}

.container.disclaimer .icon {
    /*top: 12px;*/
    left: 16px;

    /* styles for new finger icon */
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    font-size: 26px;
    line-height: 24px;
}

.container.disclaimer span {
    font-weight: 500;
}

.title {
    font-weight: 700;
    color: #212121;
    margin-bottom: 6px;
    font-size: 14px;
}

.icon {
    position: absolute;
    top: 8px;
    left: 8px;
}

.iconInLine {
    top: 8px !important;
}