.wrapper {
    display: flex;
    gap: 15px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 76px;
}
.continueBtn:disabled {
    cursor: not-allowed;
}
