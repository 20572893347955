.btnContainer .btn {
    padding: 14px 20px;
    min-height: 52px;
    background-color: #4C61C7;
}

.btnContainer .btn.transparent {
    background-color: transparent;
    color: #3749AB;
}

.btnContainer .btn.transparent p {
    color: #3749AB;
}

.btnContainer .btn.transparent:hover:not(:disabled) {
    background-color: transparent;
}

.btnContainer .btn:hover:not(:disabled) {
    background-color: #4C61C7;
}

.btnContainer .btn:active:not(:disabled) {
    background-color: #4C61C7;
}

.btnContainer .btn:disabled {
    background-color: #E7E7F4;
}

.btnContainer .btn p {
    font-family: Inter, sans-serif;
    text-transform: none;
    font-size: 16px;
    letter-spacing: normal;
}

.animation {
    width: calc(100% - 20px);
    animation: pulsar 2s infinite;
    margin: 0 auto;
    display: block;
    position: relative;
}

@keyframes pulsar {
    0% {
        box-shadow: 0 0 0 0 #4C61C7;
    }

    100% {
        box-shadow: 0 0 0 8px transparent;
    }
}
