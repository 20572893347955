.sectionStepTitle {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: -0.408px;
    margin: 7px auto;
}

.headerContainer {
    box-sizing: border-box;
    /*height: 64px;*/
    flex-shrink: 0;
    padding: 16px 16px 4px;
    /*height: 49px;*/
}

.backArrow {
    margin-left: 7px;
    padding: 8px 8px 8px 0;
    cursor: pointer;
    position: absolute;
}

/* Header css */
.container {
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    height: 33px;
    position: relative;
}

.disabledSmoothScroll {
    position: fixed;
    z-index: 10;
    width: 100%;
}

.logo {
    margin: 7px auto;
}

.skip {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
}