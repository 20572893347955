.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
}

.units {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    position: absolute;
    left: 136px;
    top: 13px;
    color: #3749AB;
}

.input {
    padding: 7px 16px;
    max-width: 124px;
    border: 1px solid #E0DEEC;
    border-radius: 14px;
    font-family: Sora, sans-serif;
    font-size: 32px;
    font-weight: 800;
    color: #3749AB;
    text-align: center;
}

.input::placeholder {
    color: #D1D1E7;
}

/*.input:focus:not(:disabled) {*/
.input:not(:disabled) {
    transition: border .2s ease-out;
    border: 1px solid #4C61C7;
}

.input.inputError {
    border: 1px solid #F84763 !important;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input[type=number], .input[type=text] {
    -moz-appearance: textfield;
}

.label {
    position: relative;
    margin-bottom: 32px;
}

.label.year .input {
    max-width: 164px;
}

.label.year .units {
    left: 176px;
}

/* type='feetHeight'*/
.label.feetHeight .input {
    max-width: 80px;
}

.label.feetHeight .units {
    left: calc(80px + 16px);
}

/* type='cmHeight'*/
.label.cmHeight .input {
    max-width: 124px;
}

.label.cmHeight .units {
    left: calc(124px + 16px);
}

/* type='name'*/
.label.text .input {
    max-width: 300px;
    border: unset;
    background: unset;
}

.label.text .input.inputError {
    border: unset !important;
}

.simpleFailTitle {
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #F84763;
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
}