/* Marked Progress Bar*/
.barContainer {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.bar {
    position: absolute;
    z-index: 1;
    height: 4px;
    border-radius: 4px;
    background: #A0B1EF;
}

.barProgressItem {
    display: flex;
    align-items: center;
}

.barProgress {
    position: relative;
    height: 4px;
    flex-grow: 1;
}

.barEl {
    position: absolute;
    height: 4px;
    /*border-radius: 4px;*/
    inset: 0px;
    background: #E7E7F4;
}

.barEl1 {
    position: absolute;
    inset: 0px;
    z-index: 2;
    background: #4C61C7;
}


/* Marker */
.markerContainer {
    position: relative;
    z-index: 2;
    height: 12px;
    width: 12px;
}

.markerContainerItem {
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    background: #E7E7F4;
}

.markerAnimated {
    position: absolute;
    inset: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #4C61C7;
}