.priceContainer {
    margin-bottom: 32px;
}

.priceBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.payPal,
.googlePay {
    width: 325px;
    max-width: 100%;
    min-height: 45px;
    margin: 0 auto;
    border-radius: 8px;
    display: flex;
    justify-content: center;
}

.payPal {
    background: #ffc439;
}

.payPal.disabled {
    pointer-events: none;
}

.disabled:after {
    content: '';
    width: 100%;
    position: absolute;
    z-index: 112;
    left: 0;
    height: 45px;
    background: #00000017;
    border-radius: 23px;
}

.hint {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: green;
    margin: 16px 0 20px;
}

.applePay {
    height: 44px;
    background-color: green;
    width: 100%;
    color: green;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.38px;
    border-radius: 8px;
}

.applePay span {
    font-size: 23px;
    line-height: 27px;
    text-align: center;
    letter-spacing: -1.22318px;
}

.closePopup {
    position: absolute;
    top: 12px;
    right: 20px;
    width: 24px;
    height: 24px;
}

.closePopupFixed {
    position: fixed;
}

.closePopup:before,
.closePopup:after {
    content: '';
    position: absolute;
    width: 24px;
    top: 50%;
    left: 50%;
    height: 2px;
    background-color: #706D8C;
}

.closePopup__small {
    top: 14px;
    right: 14px;
    width: 14px;
    height: 14px;
}

.closePopup__small:before,
.closePopup__small:after {
    width: 16px;
    height: 2px;
    border-radius: 5px;
}

.closePopup__left {
    top: 14px;
    left: 14px;
    right: unset;
}

.notificationModal__close {
    top: 17px;
    right: 17px;
    width: 14px;
    height: 14px;
}

.notificationModal__close:before,
.notificationModal__close:after {
    width: 20px;
}

.closePopup:before {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.closePopup:after {
    transform: translate(-50%, -50%) rotate(45deg);
}

.or {
    color: green;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
}

.or:before,
.or:after {
    content: '';
    position: absolute;
    width: calc(50% - 20px);
    height: 1px;
    background-color: #e0e0e0;
    top: 50%;
    transform: translateY(-50%);
}

.or:before {
    left: 0;
}

.or:after {
    right: 0;
}
