.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.topPanel {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    height: 24px;
}

.line {
    width: 33px;
    height: 5px;
    border-radius: 10px;
    background: #E7E7F4;
}

.title {
    margin-bottom: 14px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #322E48;
}

.content {
    color: #322E48 !important;
    font-weight: 400 !important;
}

.content span {
    font-weight: 600 !important;
}

.offer {
    color: #55BE8B !important;
}

.lastCheck {
    flex-grow: 1;
    max-height: 130px;
}

.btnContainer {
    margin-bottom: 16px;
}