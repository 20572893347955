.btnContainer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 121px;
    left: 0;
    display: flex;
    align-items: flex-end;
    z-index: 10000;
    padding: 0 16px 16px;
}

.shadow {
    background: linear-gradient(180deg, rgba(247, 247, 247, 0) 0%, #F8F8F8 41.89%);;
}

.double {
    height: 160px;
}

.position {
    width: 100%;
}

.position button {
    display: block;
}

.btn {
    width: 100%;
    background-color: #4C61C7;
    border-radius: 14px;
    cursor: pointer;
}

.btn:disabled {
    background-color: #E7E7F4;
}

.btn:disabled .btnTitle {
    color: #A19EBE;
}

.btn .btnTitle {
    color: #FFFFFF;
    font-weight: 700;
    padding: 14px;
}

@media screen and (min-width: 769px) {
    .btn {
        margin: 0 auto;
        max-width: 576px;
    }
}

@media screen and (min-width: 1440px) {
    .btn {
        margin: 0 auto;
        max-width: 576px;
    }
}