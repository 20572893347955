html {
    font-family: inter, sans-serif;
    font-size: 0;
    background-color: var(--background-color);
}

body,
html,
#app {
    min-height: 100%;
}

#app {
    position: relative;
    font-size: 16px;
}
